import React, { useState, useRef, useEffect } from 'react';
import './ActivationArea.scss';
import QRCode from './QRCode';
import phoneIcon from '../assets/phone-icon.svg';
import textIcon from '../assets/text-icon.svg';
import appendIcon from '../assets/append-icon.svg';
import qChatIcon from '../assets/GroupChat-icon.svg';
import linkIcon from '../assets/link-icon.svg';
import { useParams } from 'react-router-dom';
import texts from '../texts';
import TagInput from './TagInput';

const colorToActionMap = {
  '#0ACF83': 'PhoneNumber',
  '#1ABCFE': 'TextToRead',
  '#A259FF': 'AppendText',
  '#FF7262': 'GroupChat',
  '#F24E1E': 'Link',
};
const actionsList = ['PhoneNumber', 'TextToRead', 'AppendText', 'GroupChat', 'Link'];

const ActivationArea = ({ colorid }) => {
  const { linkid } = useParams();
  const [isPublic, setIsPublic] = useState(false);
  const [selectedAction, setSelectedAction] = useState(actionsList[0]);
  const [inputValue, setInputValue] = useState('');
  const [draftValue, setDraftValue] = useState('');
  const [language, setLanguage] = useState('farsi');
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [buttonText, setButtonText] = useState(texts.activationButton[language]);
  const [disableActions, setDisableActions] = useState(false);
  const [isOnboarding, setIsOnboarding] = useState(false);
  const [isSliding, setIsSliding] = useState(false);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    if (colorid && colorToActionMap[colorid]) {
      setSelectedAction(colorToActionMap[colorid]);
    }
  }, [colorid]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isOnboarding) return;
      setIsSliding(false);

      setTimeout(() => {
        setSelectedAction((prevAction) => {
          const currentIndex = actionsList.indexOf(prevAction);
          const nextIndex = (currentIndex + 1) % actionsList.length;
          return actionsList[nextIndex];
        });
      }, 500);

      setTimeout(() => {
        setIsSliding(false);
      }, 500);
    }, 2500);

    return () => clearInterval(interval);
  }, [isOnboarding]);

  const handleUserInteraction = () => {
    if (isOnboarding) {
      setIsOnboarding(false);
    }
  };

  const handleActionSelect = (action) => {
    if (!disableActions) {
      setSelectedAction(action);
      resetForm();
      setIsOnboarding(false);
    }
  };

  useEffect(() => {
    if (isOnboarding) {
      window.addEventListener('click', handleUserInteraction);
      window.addEventListener('touchstart', handleUserInteraction);

      return () => {
        window.removeEventListener('click', handleUserInteraction);
        window.removeEventListener('touchstart', handleUserInteraction);
      };
    }
  }, [isOnboarding]);

  const resetForm = () => {
    setInputValue('');
    setDraftValue('');
    setSuccessMessage('');
    setErrorMessage('');
    setButtonText(texts.activationButton[language]);
  };

  const handleInputChange = (e) => {
    let input = e.target.value;

    if (selectedAction === 'GroupChat') {
      if (input.length > 36) {
        return;
      }
    }

    setInputValue(input);
    setDraftValue(input);

    if (isInputValid(input)) {
      setDisableActions(true);
    } else {
      setDisableActions(false);
    }
  };

  const isInputValid = (input = inputValue) => {
    let valid = false;
    if (selectedAction === 'PhoneNumber') {
      valid = /^09\d{9}$/.test(input);
    } else if (selectedAction === 'GroupChat') {
      valid = input.trim() !== '' && input.length <= 36;
    } else {
      valid = input.trim() !== '';
    }
    return valid;
  };

  const handleActivation = async () => {
    const trimmedInput = selectedAction === 'TextToRead' || selectedAction === 'AppendText' ? inputValue : inputValue.trim();

    if (!isInputValid(trimmedInput)) return;

    const payload = {
      action: selectedAction,
      input: selectedAction === 'GroupChat' ? trimmedInput.replace(/^@/, '') : trimmedInput,
      colorid: colorid,
      isPublic: isPublic,
      tags: isPublic ? tags : []
    };

    console.log("Final payload being sent:", JSON.stringify(payload, null, 2));

    try {
      setLoading(true);
      setButtonText("در حال فعالسازی...");
      const response = await fetch(`https://d1worker.sapienssimple.workers.dev/activate/${linkid}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (data.success) {
        setSuccessMessage(texts.activationSuccess[language]);
        setButtonText("با موفقیت فعال شد!");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        setErrorMessage(texts.activationFailure[language]);
        setButtonText(texts.activationButton[language]);
      }
    } catch (error) {
      setErrorMessage(texts.activationError[language]);
      setButtonText(texts.activationButton[language]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='activation-area'>
      <div className='qr-code-container'>
        <QRCode linkid={linkid} />
      </div>
      <div className={`action-explanation ${isSliding ? 'slide-out' : 'slide-in'}`}>
        {texts.actions[selectedAction][language]}
      </div>
      <div className='action-icons'>
        {actionsList.map((action, idx) => (
          <button
            key={action}
            className={`action ${selectedAction === action ? 'selected' : ''}`}
            onClick={() => handleActionSelect(action)}
            disabled={selectedAction !== action && disableActions} 
          >
            <img
              src={[phoneIcon, textIcon, appendIcon, qChatIcon, linkIcon][idx]}
              alt={texts.actions[action][language]}
              style={{ width: '15px', height: '15px' }}
            />
          </button>
        ))}
      </div>
      <div className='input-container'>
        {selectedAction === 'TextToRead' || selectedAction === 'AppendText' ? (
          <input
            className='input-field'
            placeholder={texts.placeholders[selectedAction][language]}
            value={inputValue}
            onChange={handleInputChange}
            rows="1" 
            style={{
              textAlign: 'right',
              direction: 'rtl',
              overflow: 'hidden',
              resize: 'none',
            }}
          />
        ) : (
          <input
            type={selectedAction === 'PhoneNumber' ? 'tel' : 'text'}
            className='input-field'
            placeholder={texts.placeholders[selectedAction][language]}
            value={inputValue}
            onChange={handleInputChange}
            style={{ textAlign: 'right', direction: 'rtl' }}
          />
        )}
      </div>
      <div className='toggle-public'>
        <span className='toggle-text'>{isPublic ? texts.toggle.public[language] : texts.toggle.private[language]}</span>
        <span className='toggle-explanation'>{isPublic ? texts.toggle.toggleExplanationPublic[language] : texts.toggle.toggleExplanationPrivate[language]}</span>
        <input type='checkbox' checked={isPublic} onChange={() => setIsPublic(!isPublic)} />
      </div>
      {isPublic ? (
        <TagInput 
          tags={tags} 
          setTags={setTags} 
          placeholder={texts.placeholders.tags[language]} 
        />
      ) : (
        <div className='accessid-input-container'>
          <div className='add-access-explanation'>
            این کیوآرمی خصوصی است. آن را در دسترس قرار ندهید!
          </div>
        </div>
      )}

      <button
        className="activation-button"
        onClick={handleActivation}
        disabled={loading || selectedAction === 'AppendText' || selectedAction === 'GroupChat'} // Disable button for AppendText and GroupChat
        >
        {loading ? "فعالسازی..." : (selectedAction === 'AppendText' || selectedAction === 'GroupChat' ? "به‌زودی..." : buttonText)} 
      </button>

      {successMessage && <div className='success-message'>{successMessage}</div>}
      {errorMessage && <div className='error-message'>{errorMessage}</div>}
    </div>
  );
};

export default ActivationArea;
