// OpeningAnimation.js
import React from 'react';
import './OpeningAnimation.scss'; // Import CSS for animation

const OpeningAnimation = () => {
    return (
        <div className="opening-animation">
            <img src="/qrmy.svg" alt="QRmy Logo" className="logo-animation" />
        </div>
    );
};

export default OpeningAnimation;
