// src/text.js

const texts = {
  homePersian: 'کیوآرمی چیه!؟',
  homeEnglish: 'What is Qrmy?',

  actions: {
    publicLabel: {
      farsi: "عمومی",
      english: "Public",
    },
    privateLabel: {
      farsi: "خصوصی",
      english: "Private",
    },
    activationDescription: {
      farsi: "لطفا یکی از اقدامات زیر را انتخاب کنید و اطلاعات مربوطه را وارد کنید.",
      english: "Please select an action below and enter the corresponding details."
    },
    PhoneNumber: {
      farsi: 'شماره تلفن',
      english: 'mobile number.',
    },
    TextToRead: {
      farsi: 'یادداشت',
      english: 'Enter the note or text you want to be read.',
    },
    AppendText: {  
      farsi: 'سئوال',
      english: 'Question',
    },
    GroupChat: {
      farsi: 'گفتگوی امن',
      english: 'Secure chat',
    },
    Link: {
      farsi: 'لینک',
      english: 'Link.',
    },
  },
  
  placeholders: {
    tags: {
      farsi: 'تگ‌های مرتبط وارد کنید',
      english: 'Tags',
    },
    PhoneNumber: {
      farsi: 'تلفن مانند: 09123456789 یا 02121212121',
      english: 'Example: 09123456789',
    },
    TextToRead: {
      farsi: 'میخوای چی یادت بمونه!؟',
      english: 'What do you want to remember!?',
    },
    AppendText: {  
      farsi: 'چه سئوالی ذهن‌ات رو درگیر کرده!؟',
      english: 'What is on your mind!?',
    },
    GroupChat: {
      farsi: 'اسم گفتگوی امن‌ات چی باشه!؟',
      english: 'What is the name of your secret chat!?',
    },
    Link: {
      farsi: 'لینک مانند: Link.com یا t.me/username',
      english: 'Link.com * t.me/username',
    },
  },
  
  toggle: {
    public: {
      farsi: 'عـمـومـی',
      english: 'Public',
    },
    private: {
      farsi: 'خصوصی',
      english: 'Private',
    },
    toggleExplanationPublic: {
      farsi: 'در اکسپلور نمایش داده خواهد شد.',
      english: 'Will be displayed in the explore.',
    },
    toggleExplanationPrivate: {
      farsi: 'در اکسپلور نمایش داده نمیشود.',
      english: 'Will not be displayed in the explore.',
    },
  },
  
  validationMessages: {
    valid: {
      farsi: 'شماره معتبر است.',
      english: 'The number is valid',
    },
    invalid: {
      farsi: 'شماره معتبر نیست.',
      english: 'The number is invalid',
    },
  },
  
  activationButton: {
    farsi: 'بزن بریم',
    english: 'Activate Qrmy',
  },
  
  captchaText: {
    farsi: '',
    english: '',
  },
  
  activationSuccess: {
    farsi: 'در حال هدایت به لینک فعال شده...',
    english: 'Link activated successfully!',
  },
  
  activationFailure: {
    farsi: 'دوباره بزن',
    english: 'Activation failed.',
  },
  
  errorMessage: {
    farsi: 'دوباره فعالش کن!',
    english: 'Error during activation.',
  },
  
  activationTitle: {
    farsi: "عنوان فعال سازی",
    english: "Activation Title",
  },
  
  activationError: {
    farsi: "خطا در برقراری ارتباط. لطفاً بعداً امتحان کنید.",
    english: "Error connecting. Please try again later.",
  },
  
  tags: {
    farsi: "تگ بزنید",
    english: "Enter tags",
  },

  tagInput: {
    title: {
      farsi: 'ورود تگ ها',
      english: 'Enter Tags',
    },
    error: {
      farsi: 'حداکثر 5 تگ می‌توانید انتخاب کنید.',
      english: 'You can select up to 5 tags.',
    },
    mainCategoryError: {
      farsi: 'لطفا حداقل یک دسته اصلی انتخاب کنید.',
      english: 'Please select at least one main category.',
    },
    saveButton: {
      farsi: 'ذخیره تگ ها',
      english: 'Save Tags',
    },
    noOptions: {
      farsi: 'هیچ پیشنهادی موجود نیست',
      english: 'No recommendations',
    },
    selectedTags: {
      farsi: 'تگ های انتخاب شده:',
      english: 'Selected Tags:',
    },
  },
};

export default texts;
