import React, { useEffect, useState } from 'react';
import './Explore.scss'; // Ensure this CSS file is included for styling
import TimeDisplay from './TimeDisplay'; // Import the TimeDisplay component
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation

const Explore = () => {
  const [activations, setActivations] = useState([]);
  const [connected, setConnected] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate hook

  useEffect(() => {
    const eventSource = new EventSource('https://explore.sapienssimple.workers.dev/live-updates');

    // Handle successful connection
    eventSource.onopen = () => {
      console.log('Connected to live updates stream');
      setConnected(true);
    };

    // Handle incoming activation events
    eventSource.addEventListener('activation', (event) => {
      try {
        const newActivation = JSON.parse(event.data);
        console.log('New activation received:', newActivation);

        setActivations((prevActivations) => {
          // Check if the linkid already exists to avoid duplicates
          const existingLink = prevActivations.find(act => act.linkid === newActivation.linkid);
          if (!existingLink) {
            // Add the new activation and sort by activatedAt in descending order
            return [newActivation, ...prevActivations].sort((a, b) => new Date(b.activatedAt) - new Date(a.activatedAt));
          }
          return prevActivations; // If linkid already exists, return previous activations
        });
      } catch (error) {
        console.error('Failed to parse activation event:', error);
      }
    });

    // Handle connection errors
    eventSource.onerror = (error) => {
      console.error('Error with SSE connection:', error);
      setConnected(false);
    };

    // Cleanup the event source on component unmount
    return () => {
      console.log('Closing SSE connection');
      eventSource.close();
      setConnected(false);
    };
  }, []);

  // Function to detect phone number in the action field
  const containsPhoneNumber = (action) => {
    const phoneRegex = /(\+?\d{1,4}[-.\s]?)?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}/;
    return phoneRegex.test(action);
  };

  // Function to handle card click and navigate to the linkid route
  const handleCardClick = (linkid) => {
    navigate(`/${linkid}`);
  };

  return (
    <div className="explore-container">
      {!connected && <p></p>}
      <div className="activation-grid">
        {activations.length === 0 ? (
          <p>یه لحظه وایسا...</p>
        ) : (
          activations.map((activation, index) => (
            <div
              key={index}
              className="activation-card"
              onClick={() => handleCardClick(activation.linkid)} // Add onClick to navigate on card click
            >
              <TimeDisplay timestamp={activation.activatedAt} /> {/* Use TimeDisplay for time */}
              {/* Only display the action if it doesn't contain a phone number */}
              {!containsPhoneNumber(activation.action) && (
                <p><strong>{activation.linkid}</strong></p>
              )}
              <p className="user-data">{activation.userData}</p>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Explore;
