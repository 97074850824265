import React, { useState, useEffect } from 'react';
import './TagInput.scss';

const TagInput = ({ tags, setTags, placeholder }) => {
  const [inputValue, setInputValue] = useState('');
  const [originalTags, setOriginalTags] = useState([]);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const categoryColors = {
    'living day': '#B0D0D3',
    lifestyle: '#E6B2A5',
    activities: '#D1E7B8',
    'to-dos': '#F5E6B2',
    'payable daily services': '#D5C2E0',
    routines: '#F0C6B4',
    'social interactions': '#F2C8C6',
    memories: '#C6E0F3',
  };

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await fetch('https://tag.sapienssimple.workers.dev/tags');
        const data = await response.json();
        setOriginalTags(data);
        setFilteredSuggestions(data);
      } catch (error) {
        console.error('Error fetching tags:', error);
      }
    };

    fetchTags();
  }, []);

  const handleInputChange = (e) => {
    const userInput = e.target.value;
    setInputValue(userInput);

    const filtered = originalTags.filter((suggestion) =>
      suggestion.tag.toLowerCase().includes(userInput.toLowerCase())
    );
    setFilteredSuggestions(filtered);
    setShowSuggestions(!!userInput && filtered.length > 0);
  };

  const addTag = (tag) => {
    if (!tags.some(t => t.tag === tag.tag)) {
      setTags([...tags, tag]);
    }
    setInputValue('');
    setShowSuggestions(false);
  };

  const removeTag = (indexToRemove) => {
    setTags(tags.filter((_, index) => index !== indexToRemove));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && inputValue) {
      const selectedTag = filteredSuggestions.find(suggestion => suggestion.tag === inputValue);
      if (selectedTag) {
        addTag(selectedTag);
      }
    }
  };

  return (
    <div className="tag-input-container">
      <div className="tags-wrapper">
        {tags.map((tag, index) => (
          <div
            className="tag"
            key={`${tag.tag}-${index}`}
            style={{ backgroundColor: categoryColors[tag.category] }}
          >
            {tag.tag}
            <span className="close" onClick={() => removeTag(index)}>
              &times;
            </span>
          </div>
        ))}
        <input
          type="text"
          className="tag-input"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder={placeholder || 'Add tags...'}
        />
      </div>
      {showSuggestions && (
        <div className="suggestions">
          {filteredSuggestions.map((suggestion, index) => (
            <div
              key={`${suggestion.tag}-${index}`}
              className="tag suggestion"
              style={{ backgroundColor: categoryColors[suggestion.category] }}
              onClick={() => addTag(suggestion)}
            >
              {suggestion.tag}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TagInput;
