import React, { useState, useEffect } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import './ActivationArea.scss'; // Ensure this contains your custom styles

const AnimatedQRCode = ({ linkid }) => {
  const [isZooming, setIsZooming] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsZooming(prev => !prev);
    }, 2000); // Change zoom every 2 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <QRCodeSVG
        value={`qrmy.ir/${linkid}`}
        size={80}
        className={`qr-code ${isZooming ? 'zoom-in' : 'zoom-out'}`} // Use zoom classes
        fgColor="var(--qr-code-fg-color)" // Use CSS variable here
        bgColor="transparent" // Set background color to transparent
        level="L" // Only show L level
      />
    </div>
  );
};

export default AnimatedQRCode;
