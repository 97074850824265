// Header.js
// Updated on 2024-10-20
// This component represents the header section of the landing page.
// It includes the logo and navigation links, allowing users to navigate to the home page.

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.scss';

const Header = ({ linkId, pageName }) => { // Accept pageName prop
    const [copied, setCopied] = useState(false);

    // Function to handle copying linkId to clipboard
    const handleCopy = () => {
        if (linkId) {
            navigator.clipboard.writeText(linkId)
                .then(() => {
                    setCopied(true);
                    setTimeout(() => setCopied(false), 2000); // Hide after 2 seconds
                })
                .catch(err => {
                    console.error('Failed to copy: ', err);
                });
        }
    };

    return (
        <header className="landing-header">
            <nav className="navigation">
                {linkId ? ( // Display linkId if it exists
                    <div className="link-container" style={{ display: 'flex', alignItems: 'center' }}>
                        {copied && (
                            <span className="copy-message" style={{ marginLeft: '8px', color: 'gray' }}>
                                کپی شد...
                            </span>
                        )}
                        <p className="qlink-id" onClick={handleCopy} style={{ cursor: 'pointer', margin: 0 }}>
                            {linkId}
                        </p>
                    </div>
                ) : ( // Display pageName if linkId does not exist
                    <h2 className="page-name" style={{ margin: 0 }}>
                        {pageName}
                    </h2>
                )}
                <Link to="/explore" className="logo" style={{ "--color-id": "--color-id" }}>
                    <img src="/qrmy.svg" alt="qrmy logo" />
                </Link>
            </nav>
        </header>
    );
};

export default Header;
