import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useParams, useLocation } from 'react-router-dom';
import ActivationPage from './components/ActivationPage';
import ReadText from './components/ReadText';
import AppendText from './components/AppendText';
import NotFound from './components/NotFound';
import OpeningAnimation from './components/OpeningAnimation';
import LandingPage from './components/LandingPage';
import Header from './components/Header';
import Footer from './components/Footer';
import Explore from './components/Explore';
import GroupChat from './components/GroupChat';
import './styles/fonts.scss';

// Custom hook for fetching link data
const useFetchLinkData = (linkid, setLinkStatus, setColorId) => {
    useEffect(() => {
        if (!linkid) {
            setLinkStatus({ status: 400, isActive: false });
            return;
        }

        const fetchLinkData = async () => {
            try {
                const response = await fetch(`https://d1worker.sapienssimple.workers.dev/${linkid}`);
                if (response.ok) {
                    const data = await response.json();
                    setLinkStatus(data);
                    setColorId(data.colorid); // Set colorid from fetched data
                } else {
                    setLinkStatus({ status: response.status, isActive: false });
                }
            } catch {
                setLinkStatus({ status: 500, isActive: false });
            }
        };
        fetchLinkData();
    }, [linkid, setLinkStatus, setColorId]);
};

const App = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [linkStatus, setLinkStatus] = useState(null);
    const [linkId, setLinkId] = useState(null);
    const [colorId, setColorId] = useState(null); // State to hold colorId
    const [pageName, setPageName] = useState('اکسپلور');

    useEffect(() => {
        const timer = setTimeout(() => setIsLoading(false), 2000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <div style={{ '--color-id': colorId || '#000' }}> {/* Fallback to black if colorId is not available */}
            <Router>
                {isLoading ? <OpeningAnimation /> : <MainContent linkId={linkId} colorId={colorId} linkStatus={linkStatus} setLinkStatus={setLinkStatus} setLinkId={setLinkId} setColorId={setColorId} setPageName={setPageName} />}
            </Router>
        </div>
    );
};

const MainContent = ({ linkId, colorId, linkStatus, setLinkStatus, setLinkId, setColorId, setPageName }) => {
    const location = useLocation();

    return (
        <>
            <Header linkId={linkId} colorId={colorId} pageName={linkId ? 'Link Details' : 'Explore'} />
            <Routes>
                <Route path="/" element={<Explore />} />
                <Route path="/explore" element={<Explore />} />
                <Route path="/:linkid" element={<LinkHandler linkStatus={linkStatus} setLinkStatus={setLinkStatus} setLinkId={setLinkId} setColorId={setColorId} colorId={colorId} setPageName={setPageName} />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
            {!(linkStatus?.status === 200 && (linkStatus?.action === 'GroupChat' || linkStatus?.action === 'GroupChatAuth')) && <Footer />}
        </>
    );
};

const LinkHandler = ({ linkStatus, setLinkStatus, setLinkId, setColorId, colorId, setPageName }) => {
    const { linkid } = useParams();
    const [initialMessages, setInitialMessages] = useState([]);

    useEffect(() => {
        setLinkId(linkid);
    }, [linkid, setLinkId]);

    // Update page name based on linkid only when it changes
    useEffect(() => {
        setPageName(linkid ? 'Link Details' : 'Not Found');
    }, [linkid, setPageName]);

    useFetchLinkData(linkid, setLinkStatus, setColorId);

    useEffect(() => {
        const fetchInitialMessages = async () => {
            if (!linkid) return;
            try {
                const response = await fetch(`https://groupchat.sapienssimple.workers.dev/msgs/${linkid}`);
                if (response.ok) {
                    const messages = await response.json();
                    setInitialMessages(messages);
                }
            } catch {
                console.error('Error fetching initial messages');
            }
        };
        fetchInitialMessages();
    }, [linkid]);

    if (!linkStatus) return <div className="loading-message">یه لحظه وایسا...</div>;

    const { status, action, userData, activatedAt } = linkStatus;
    // Style for the linkId with dynamic border color
    const linkIdStyle = {
        borderBottom: `2px solid ${colorId || '#000'}`, // Fallback to black if colorId is not available
    };

    switch (status) {
        case 201:
            return <ActivationPage linkid={linkid} colorid={colorId} onActivationComplete={() => setLinkStatus(null)} />; // Pass colorid to ActivationPage
        case 200:
            if (action === 'GroupChat' || action === 'GroupChatAuth') {
                const wsUrl = `wss://groupchat.sapienssimple.workers.dev/${linkid}`;
                return <GroupChat wsUrl={wsUrl} initialMessages={initialMessages} colorid={colorId} />; // Pass colorid to GroupChat
            }
            switch (action) {
                case 'Link':
                    // Ensure userData has https:// prefix
                    const userDataUrl = userData.startsWith('http') ? userData : `https://${userData}`;
                    window.location.href = userDataUrl; 
                    return null;
                case 'InstagramID':
                    window.location.href = `https://www.instagram.com/${userData}/`; 
                    return null;
                case 'PhoneNumber':
                    window.location.href = `tel:${userData}`; 
                    return null;
                case 'TextToRead':
                    return <ReadText linkid={linkid} colorid={colorId} activationData={{ userData, activatedAt }} />; // Pass colorid to ReadText
                case 'AppendText':
                    return <AppendText linkid={linkid} colorid={colorId} activationData={{ userData, activatedAt }} />; // Pass colorid to AppendText
                default:
                    return <LandingPage />;
            }
        case 404:
            setPageName('Not Found');
            return <NotFound />;
        case 500:
            return <div>فعلا خبری نیست تا ببینیم چی پیش میاد...</div>;
        default:
            return (
                <div>
                    <span className="linkid" style={linkIdStyle}>{linkid}</span> {/* Display linkId with style */}
                </div>
            );
    }
};

export default App;
