import React, { useEffect, useState, useRef } from 'react';
import './AppendText.scss';
import sendIcon from '../assets/send-icon.svg';
import TimeDisplay from './TimeDisplay';

const AppendText = ({ linkid }) => {
    const [textContent, setTextContent] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [newUserData, setNewUserData] = useState('');
    const [activatedAt, setActivatedAt] = useState('');
    const [ws, setWs] = useState(null);
    const [isWsConnected, setIsWsConnected] = useState(true);
    const scrollRef = useRef(null);
    const debounceTimeoutRef = useRef(null); // Ref to store timeout ID

    const detectLanguage = (text) => {
        const persianRegex = /[\u0600-\u06FF]/;
        return persianRegex.test(text) ? 'fa' : 'en';
    };

    const fetchAppendedData = async () => {
        setLoading(true);
        try {
            const response = await fetch(`https://append.sapienssimple.workers.dev/retrieve/${linkid}`);
            const data = await response.json();

            if (data.success) {
                const activationData = data.data.activationData;
                const formattedActivationData = {
                    input: activationData.userData || "",
                    activatedAt: activationData.activatedAt,
                    language: detectLanguage(activationData.userData || ''),
                };

                const formattedData = [formattedActivationData];

                if (Array.isArray(data.data.appendedData) && data.data.appendedData.length > 0) {
                    const appendedData = data.data.appendedData.map((item) => ({
                        input: item.input,
                        appendedAt: item.appendedAt,
                        language: detectLanguage(item.input),
                    }));
                    formattedData.push(...appendedData);
                }

                setTextContent(formattedData);
                setActivatedAt(formattedActivationData.activatedAt);
                setError(null);
            } else {
                setError('No activation data available.');
            }
        } catch (err) {
            setError('An error occurred while fetching data. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const setupWebSocket = () => {
        const socket = new WebSocket(`wss://append.sapienssimple.workers.dev/ws/${linkid}`);
        setWs(socket);

        socket.onopen = () => {
            console.log('WebSocket connection established.');
            setIsWsConnected(true);
        };

        socket.onmessage = (event) => {
            try {
                const messageData = JSON.parse(event.data);
                if (messageData.data) {
                    const newEntry = {
                        input: messageData.data.input,
                        appendedAt: messageData.data.appendedAt,
                        language: detectLanguage(messageData.data.input),
                    };

                    // Confirm the optimistic entry or add a new one if necessary
                    setTextContent((prevContent) => {
                        const optimisticIndex = prevContent.findIndex(entry => entry.optimistic);
                        if (optimisticIndex >= 0) {
                            return prevContent.map((entry, index) =>
                                index === optimisticIndex ? { ...newEntry, optimistic: false } : entry
                            );
                        } else {
                            return [...prevContent, newEntry];
                        }
                    });
                }
            } catch (error) {
                console.error('Error parsing WebSocket message:', error);
            }
        };

        socket.onerror = (error) => {
            console.error('WebSocket error:', error);
            setError('WebSocket connection error.');
        };

        socket.onclose = (event) => {
            console.log('WebSocket connection closed. Reconnecting in 3 seconds...', event);
            setIsWsConnected(false);
            setTimeout(setupWebSocket, 3000); // Attempt to reconnect
        };
    };

    useEffect(() => {
        fetchAppendedData();
        setupWebSocket();

        return () => {
            if (ws) {
                ws.close(); // Clean up WebSocket connection on unmount
            }
        };
    }, [linkid]);

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
    }, [textContent]);

    const debounce = (func, delay) => {
        return (...args) => {
            if (debounceTimeoutRef.current) {
                clearTimeout(debounceTimeoutRef.current);
            }
            debounceTimeoutRef.current = setTimeout(() => {
                func.apply(null, args);
            }, delay);
        };
    };

    const handleSubmit = debounce(async () => {
        if (newUserData.trim() === '') return;
    
        const payload = { input: newUserData };
    
        // Optimistically update UI before the server response
        const optimisticEntry = {
            input: newUserData,
            appendedAt: new Date().toISOString(), // Generate a timestamp
            language: detectLanguage(newUserData),
            optimistic: true, // Flag to mark as optimistic
        };
    
        // Update state with optimistic entry
        setTextContent((prevContent) => [...prevContent, optimisticEntry]);
        setNewUserData(''); // Clear the input field for better UX
    
        // Scroll to the bottom of the chat
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
    
        try {
            const response = await fetch(`https://append.sapienssimple.workers.dev/appendix/${linkid}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            });
    
            // Check if response is OK (status 200)
            if (!response.ok) {
                const errorResponse = await response.json();
                console.error(`Unexpected response status: ${response.status}`, errorResponse);
                setTextContent((prevContent) => prevContent.filter(entry => !entry.optimistic)); // Remove optimistic entry
                setError(errorResponse.error || `Error ${response.status}: An unexpected error occurred. Please try again.`);
                return; // Exit early to avoid further processing
            }

            const result = await response.json();
            if (result.success) {
                // On success, update the optimistic entry with confirmed data
                setTextContent((prevContent) => 
                    prevContent.map((entry) => 
                        entry.optimistic ? { ...entry, optimistic: false, appendedAt: result.data.appendedAt } : entry // Confirm optimistic entry
                    )
                );
            } else {
                // Handle server-side error response
                setTextContent((prevContent) => prevContent.filter(entry => !entry.optimistic)); // Remove optimistic entry
                setError(result.error); // Set error message from the server
            }
        } catch (err) {
            // Handle request failure, remove optimistic entry and display error
            setTextContent((prevContent) => prevContent.filter(entry => !entry.optimistic));
            setError('An error occurred while appending data. Please try again.');
        }
    }, 500); // Adjust the delay as necessary
    
    return (
        <div className="append-text-container">
            <div className="fixed-activation-message">
                <div className="text-content" lang={textContent[0]?.language || 'en'}>
                    {textContent[0]?.input || ""}
                </div>
                <TimeDisplay timestamp={activatedAt} />
            </div>
    
            <div className="scrollable-text-content" ref={scrollRef}>
                {loading && <div>Loading...</div>}
                {!loading && error && <div className="error-message">{error}</div>}
                {!loading && !error && (
                    <>
                        {/* Render Appended Messages */}
                        {textContent.slice(1).map((data, index) => (
                            <div key={index} className={`text-section ${data.optimistic ? 'optimistic' : ''}`}>
                                <div className="text-content" lang={data.language}>
                                    {data.input}
                                </div>
                                <TimeDisplay timestamp={data.appendedAt} isAppended={true} />
                                {data.optimistic && <span className="optimistic-indicator">درحال ارسال...</span>}
                            </div>
                        ))}
                    </>
                )}
            </div>
    
            <div className="fixed-input-area">
                <div className="input-container">
                    {!isWsConnected && (
                        <div className="connection-status">
                            WebSocket disconnected. Attempting to reconnect...
                        </div>
                    )}
                    <textarea
                        className="append-input"
                        value={newUserData}
                        onChange={(e) => setNewUserData(e.target.value)}
                        rows="1"
                        placeholder="متن خود را وارد کنید..."
                    />
                    <button className="submit-btn" onClick={handleSubmit}>
                        <img src={sendIcon} alt="Send" style={{ width: '40px', height: '40px' }} />
                    </button>
                </div>
            </div>
        </div>
    );       
};

export default AppendText;
