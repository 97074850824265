import React, { useEffect, useState } from 'react';

const TimeDisplay = ({ timestamp, isAppended = false }) => {
    const [showActualDate, setShowActualDate] = useState(false); // State to toggle date display
    const [formattedTime, setFormattedTime] = useState('');
    const [timer, setTimer] = useState(null); // State for timer

    useEffect(() => {
        const startTimer = () => {
            const interval = setInterval(() => {
                setFormattedTime(formatTimeElapsed(new Date(timestamp)));
            }, 1000);
            setTimer(interval);
        };

        setFormattedTime(formatTimeElapsed(new Date(timestamp)));
        startTimer();

        return () => clearInterval(timer); // Cleanup on unmount
    }, [timestamp]);

    // Function to format the time elapsed since activation
    const formatTimeElapsed = (startDate) => {
        const now = Date.now();
        const elapsedSeconds = Math.floor((now - startDate.getTime()) / 1000);

        if (elapsedSeconds < 60) return `${elapsedSeconds} ثانیه پیش`; // seconds ago
        if (elapsedSeconds < 3600) return `${Math.floor(elapsedSeconds / 60)} دقیقه پیش`; // minutes ago
        if (elapsedSeconds < 86400) return `${Math.floor(elapsedSeconds / 3600)} ساعت پیش`; // hours ago
        if (elapsedSeconds < 604800) return `${Math.floor(elapsedSeconds / 86400)} روز پیش`; // days ago
        if (elapsedSeconds < 2419200) return `${Math.floor(elapsedSeconds / 604800)} هفته پیش`; // weeks ago
        if (elapsedSeconds < 29030400) return `${Math.floor(elapsedSeconds / 2419200)} ماه پیش`; // months ago
        return `${Math.floor(elapsedSeconds / 29030400)} سال پیش`; // years ago
    };

    const handleToggleDate = () => {
        setShowActualDate((prev) => !prev); // Toggle the display state
    };

    return (
        <div className="activated-at" onClick={handleToggleDate}>
            {isAppended ? '' : 'فعال شده در: '}
            {showActualDate ? new Date(timestamp).toLocaleString() : formattedTime}
        </div>
    );
};

export default TimeDisplay;
