import React, { useEffect, useState } from 'react';
import './ReadText.scss'; // Updated import for SCSS styles
import EditText from './EditText';
import editIcon from '../assets/edit-icon.svg'; // Import edit icon asset
import TimeDisplay from './TimeDisplay'; // Import the new TimeDisplay component

const ReadText = ({ linkid, activationData }) => {
    const [textContent, setTextContent] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [language, setLanguage] = useState('fa');
    const [isEditing, setIsEditing] = useState(false);
    const [isEditable, setIsEditable] = useState(false);

    useEffect(() => {
        const detectLanguage = (text) => {
            const persianRegex = /[\u0600-\u06FF]/;
            return persianRegex.test(text) ? 'fa' : 'en';
        };

        if (activationData) {
            const textToRead = activationData.userData; // Use userData directly
            setTextContent(textToRead);
            setLanguage(detectLanguage(textToRead));

            if (activationData.activatedAt) {
                const parsedDate = new Date(activationData.activatedAt);
                if (!isNaN(parsedDate.getTime())) {
                    const now = Date.now();
                    const elapsedTime = now - parsedDate.getTime();
                    setIsEditable(elapsedTime <= 900000); // 900,000 milliseconds = 15 minutes
                } else {
                    console.error('Invalid date format:', activationData.activatedAt);
                }
            } else {
                setError('No activation date available');
            }
        } else {
            setError('No activation data available.');
        }

        setLoading(false);
    }, [activationData]);

    return (
        <div className="read-text-container">
            <div className="read-text-page">
                {!loading && !error && (
                    <>
                        {isEditing ? (
                            <EditText 
                                linkid={linkid}
                                initialText={textContent} 
                                onSave={(updatedText) => {
                                    setTextContent(updatedText);
                                    setIsEditing(false);
                                }}
                                activatedAt={activationData.activatedAt} // Original date string
                            />
                        ) : (
                            <>
                                <TimeDisplay timestamp={activationData.activatedAt} /> {/* Use TimeDisplay component */}
                                <div className="text-content" lang={language}>
                                    {textContent}
                                </div>
                                <div className="activated-at-container">
                                {isEditable && (
                                        <div className="edit-icon-container" onClick={() => setIsEditing(true)}>
                                        <img 
                                            src={editIcon} 
                                            alt="Edit" 
                                            className="edit-icon" 
                                            style={{ width: '15px', height: '15px' }} 
                                        />
                                        <span className="edit-text">ویرایش</span>
                                    </div>
                                )}
                                            </div>

                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default ReadText;
