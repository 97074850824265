import React, { useState, useEffect, useRef } from 'react';
import editedIcon from '../assets/edited-icon.svg'; // Import edit icon asset
import './EditText.scss'; // CSS file for styling

const EditText = ({ linkid, initialText, onSave, activatedAt }) => {
    const [inputValue, setInputValue] = useState(initialText); // Holds input value for actions
    const [remainingTime, setRemainingTime] = useState(900); // 15 minutes in seconds
    const [isEditing, setIsEditing] = useState(false); // Editing state
    const [loading, setLoading] = useState(false); // Loading state
    const [canEdit, setCanEdit] = useState(false); // Flag to determine if editing is allowed
    const [showActualDate, setShowActualDate] = useState(false); // State to toggle date format
    const [elapsedTime, setElapsedTime] = useState(0); // Elapsed time in seconds

    const textareaRef = useRef(null); // Ref to access textarea DOM element

    // Update inputValue and adjust height when initialText changes
    useEffect(() => {
        setInputValue(initialText);
        if (textareaRef.current) {
            const textarea = textareaRef.current;
            textarea.style.height = 'auto'; // Reset height
            textarea.style.height = `${textarea.scrollHeight}px`; // Adjust height based on content
        }
    }, [initialText]);

    // Adjust textarea height dynamically based on input value
    useEffect(() => {
        if (textareaRef.current) {
            const textarea = textareaRef.current;
            textarea.style.height = 'auto'; // Reset height
            textarea.style.height = `${textarea.scrollHeight}px`; // Adjust height based on content
        }
    }, [inputValue, isEditing]);

    // Check if editing is allowed based on activatedAt
    useEffect(() => {
        if (activatedAt) {
            const now = Date.now(); // Current timestamp
            const activatedTime = new Date(activatedAt).getTime(); // Convert activatedAt to timestamp
            const elapsed = Math.floor((now - activatedTime) / 1000); // Calculate elapsed time in seconds

            setElapsedTime(elapsed); // Set elapsed time in seconds

            // Check if 15 minutes (900 seconds) have passed
            setCanEdit(elapsed <= 900); // 900 seconds = 15 minutes

            // If more than 15 minutes have passed, reset remaining time
            if (elapsed > 900) {
                setRemainingTime(0); // Ensure remaining time is zero
            }
        }
    }, [activatedAt]);

    // Timer for remaining editing time and live elapsed time
    useEffect(() => {
        const interval = setInterval(() => {
            setElapsedTime((prev) => prev + 1); // Increment elapsed time
            if (isEditing && remainingTime > 0) {
                setRemainingTime((prev) => prev - 1); // Decrease remaining time
            }
        }, 1000);

        return () => clearInterval(interval); // Cleanup interval on unmount
    }, [isEditing, remainingTime]);

    const handleTextChange = (event) => {
        setInputValue(event.target.value); // Update inputValue
        console.log("Text input changed:", event.target.value); // Log text input changes
    };

    const handleSave = async () => {
        // Check if any of the required fields are missing
        if (!inputValue.trim()) {
            console.log("Save attempt failed: Missing input.");
            return;
        }

        // Log linkid to check if it's defined
        console.log("Current linkid:", linkid);
        if (!linkid) {
            console.error("linkid is undefined. Cannot proceed with save.");
            return;
        }

        const payload = {
            input: inputValue, // User data
        };

        console.log("Payload to be sent:", JSON.stringify(payload, null, 2)); // Log the payload before sending
        setLoading(true); // Set loading state to true

        try {
            const response = await fetch(`https://update.sapienssimple.workers.dev/update/${linkid}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload), // Ensure payload is JSON string
            });

            const result = await response.json();
            console.log("Response from server:", result); // Log server response

            if (result.success) {
                onSave(result.updatedText || inputValue); // Call onSave prop with the updated text
                setIsEditing(false); // Exit editing mode after successful save
                console.log("Save successful: Updated text:", result.updatedText); // Log successful save
            } else {
                console.log("Server error:", result.message); // Log server error
            }
        } catch (err) {
            console.error("Save operation failed:", err); // Log the error
        } finally {
            setLoading(false); // Reset loading state
        }
    };

    // Function to format the time since activation
    const formatTimeAgo = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const weeks = Math.floor(days / 7);
        const months = Math.floor(days / 30);
        const years = Math.floor(days / 365);

        if (years > 0) return `${years} سال پیش`; // Years ago
        if (months > 0) return `${months} ماه پیش`; // Months ago
        if (weeks > 0) return `${weeks} هفته پیش`; // Weeks ago
        if (days > 0) return `${days} روز پیش`; // Days ago
        if (hours > 0) return `${hours} ساعت پیش`; // Hours ago
        if (minutes > 0) return `${minutes} دقیقه پیش`; // Minutes ago
        return `${seconds} ثانیه پیش`; // Seconds ago
    };

    // Function to handle clicking on activatedAt
    const toggleDateFormat = () => {
        setShowActualDate((prev) => !prev); // Toggle the date format
    };

    return (
        <div className="edit-controls">
            <span className="activated-at" onClick={toggleDateFormat}>
                {showActualDate 
                    ? new Date(activatedAt).toLocaleString() // Show actual date and time
                    : formatTimeAgo(elapsedTime)} {/* Display formatted activatedAt */}
            </span>
            <textarea
                ref={textareaRef} // Attach ref to the textarea
                className="editable-textarea"
                value={inputValue} // Controlled input
                onChange={handleTextChange} // Handle text change
            />
            <div className="activated-at-container">
                {canEdit && remainingTime > 0 && ( // Ensure button only shows when allowed
                    <div className="edit-icon-container" onClick={handleSave}>
                        <img 
                            src={editedIcon} 
                            alt="Edit" 
                            className="edit-icon" 
                            style={{ width: '15px', height: '15px' }} 
                        />
                        <span className="edit-text">ذخیره</span> {/* Text next to the icon */}
                    </div>
                )}
            </div>
        </div>
    );
};

export default EditText;
