import React, { useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import ActivationArea from './ActivationArea'; // Import the new ActivationArea component

const ActivationPage = ({ onActivationComplete, colorid }) => {
    const { linkid } = useParams();

    // State management
    const [loading, setLoading] = useState(false);
    const [activationState, setActivationState] = useState({
        isValidLink: true,
        selectedAction: '',
        inputValue: '',
        captchaToken: null,
        siteKey: '',
        showCaptcha: false,
    });

    // Redirect to not found page if linkid is invalid
    if (!linkid) {
        return <Navigate to="/not-found" />;
    }

    // Loading indicator during activation
    if (loading) return <div className="loading">در حال بارگذاری...</div>;

    return (
        <div className="activation-container">
            <div className="activation-page">
                <ActivationArea 
                    activationState={activationState} 
                    onActivationComplete={onActivationComplete} // Pass the complete handler
                    colorid={colorid} // Pass colorid to ActivationArea
                />
            </div>
        </div>
    );
};

export default ActivationPage;
