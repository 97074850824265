import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import './GroupChat.scss';

const GroupChat = ({ wsUrl, initialMessages = [] }) => {
  const linkId = window.location.pathname.split('/')[1];
  const clientId = linkId; // Assuming clientId is the same as linkId

  const effectiveId = useMemo(() => clientId || linkId, [clientId, linkId]);

  const [messages, setMessages] = useState(initialMessages);
  const [onlineClients, setOnlineClients] = useState([]); // State to manage online clients
  const [input, setInput] = useState('');
  const [socket, setSocket] = useState(null);
  const [error, setError] = useState(null);
  const messagesEndRef = useRef(null);
  const reconnectIntervalRef = useRef(null);
  const textareaRef = useRef(null);

  useEffect(() => setMessages(initialMessages), [initialMessages]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const connectWebSocket = useCallback((retryTimeout = 1000) => {
    console.log(`Connecting to WebSocket at ${wsUrl}...`);
    const ws = new WebSocket(wsUrl);
    setSocket(ws);

    ws.onopen = () => {
      console.log('WebSocket connection established.');
      setError(null);
      clearInterval(reconnectIntervalRef.current);
      ws.send(JSON.stringify({ clientId: effectiveId, linkId: linkId })); // Send both clientId and linkId
    };

    ws.onmessage = (event) => {
      const newMessage = JSON.parse(event.data);
      console.log('Message received:', newMessage);
      // Check if the message is an online status update
      if (newMessage.type === 'onlineStatus') {
        // Update online clients list
        setOnlineClients(newMessage.onlineClients);
      } else {
        // Treat as a normal chat message
        setMessages((prevMessages) => [...prevMessages, newMessage]);
      }
    };

    ws.onclose = () => {
      console.log('WebSocket connection closed.');
      setError('Connection closed. Reconnecting...');
      reconnectIntervalRef.current = setTimeout(() => {
        console.log('Attempting to reconnect...');
        connectWebSocket(Math.min(retryTimeout * 2, 30000));
      }, retryTimeout);
    };

    ws.onerror = (event) => {
      console.error('WebSocket error:', event);
      setError('WebSocket error. Please check your connection.');
    };
  }, [wsUrl, effectiveId, linkId]); // Added linkId as a dependency

  useEffect(() => {
    connectWebSocket();
    return () => {
      if (socket) {
        console.log('Closing WebSocket connection...');
        socket.close();
      }
      clearTimeout(reconnectIntervalRef.current);
    };
  }, [connectWebSocket]);

  useEffect(() => scrollToBottom(), [messages]);

  const sendMessage = () => {
    if (socket && socket.readyState === WebSocket.OPEN && input.trim()) {
      const message = { 
        text: input, 
        clientId: effectiveId.replace(/-/g, ''), // Remove hyphens
        linkId: linkId, // Include linkId in the message payload
        timestamp: new Date().toISOString()
      };
      console.log('Sending message:', message);
      setMessages((prevMessages) => [...prevMessages, message]); // Optimistic UI
      setInput('');
      socket.send(JSON.stringify(message));
    } else {
      setError(input.trim() ? 'Connection not open.' : 'Message cannot be empty.');
      console.log('Failed to send message:', input.trim() ? 'Connection not open' : 'Message is empty');
    }
  };

  return (
    <div className="chat-container">
      {error && <div className="error-message">{error}</div>}
      <div className="chat-messages">
        {messages.length === 0 ? (
          <div>هرچه میخواهد دل تنگت بگو...</div>
        ) : (
          messages.map((msg, index) => (
            <div key={index} className="message-wrapper">
              <div className="chat-message">
                {msg.text}
                <span className="timestamp">{new Date(msg.timestamp).toLocaleTimeString()}</span>
                {msg.clientId === effectiveId.replace(/-/g, '') && ( // Check if this is the last message from this client
                  <span className="client-id">{msg.clientId}</span> // Display client ID
                )}
                {onlineClients.includes(msg.clientId) && ( // Check if the client is online
                  <span className="status-indicator online"></span> // Online status indicator
                )}
              </div>
            </div>
          ))
        )}
        <div ref={messagesEndRef} />
      </div>
      <div className="chat-input">
        <textarea 
          ref={textareaRef} 
          value={input} 
          onChange={(e) => setInput(e.target.value)} 
          rows={1} 
          maxLength={300} 
          placeholder="Type a message..." 
        />
        <button onClick={sendMessage}>Send</button>
      </div>
    </div>
  );
};

export default GroupChat;
